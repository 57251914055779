import React, { useEffect, useState } from 'react'
import Summary from '@src/pages/Forms/Candidate/InterviewProgress/Summary'
import { Route, Switch, useParams } from 'react-router-dom'
import { ROUTES } from '@src/constants/routes'
import {
  CandidateInterface,
  InterviewRoundInterface,
} from '@src/interfaces/interviewTool'
import { getCandidate, getInterviewRound } from '@src/api/recruitment/interviews'
import { connect } from 'lape'
import { PageWrapper } from '@components/Page/Page'
import { Box, BREAKPOINTS, Flex } from '@revolut/ui-kit'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { pathToUrl } from '@src/utils/router'
import Title from '@src/pages/Forms/Candidate/InterviewProgress/components/Title'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'
import Emails from '@src/pages/Forms/Candidate/Emails/Emails'
import Comments from '@src/pages/Forms/Candidate/Comments/Comments'
import {
  invalidateCandidateEmailThreads,
  useGetCandidateStats,
} from '@src/api/hiringProcess'
import QuickSummaryCount from '@components/QuickSummary/QuickSummaryCount'
import Timeline from '@src/pages/Forms/Candidate/Timeline/Timeline'
import Forms from '@src/pages/Forms/Candidate/Forms/Forms'
import { useSetDocumentTitle } from '@src/hooks/useSetDocumentTitle'
import Documents from '@src/pages/Forms/Candidate/Documents/Documents'
import AnonymizedCandidate from './AnonymizedCandidate/AnonymizedCandidate'
import { useGetCandidateSettings } from '@src/api/settings'
import { PermissionTypes } from '@src/store/auth/types'
import CandidateSubtitle from '@src/pages/Forms/Candidate/CandidateSubtitle'
import { useShowCandidateSwitcher } from '@src/pages/Forms/Candidate/utils'
import { CandidateSwitcher } from '@src/pages/Forms/Candidate/InterviewProgress/components/CandidateSwitcher'
import {
  Chat,
  Document,
  Envelope,
  Form,
  Profile,
  Timeline as TimelineIcon,
} from '@revolut/icons'
import { CandidateActions } from '@src/pages/Forms/Candidate/CandidateActions/CandidateActions'
import { CandidateProfileContextProvider } from '@src/pages/Forms/Candidate/CandidateProfileContext'
import PersonalDataSidebar from '@src/pages/Forms/Candidate/PersonalDataSidebar/PersonalDataSidebar'
import CandidateInterviewDetailsSidebar from '@src/pages/Forms/Candidate/CandidateInterviewDetailsSidebar/CandidateInterviewDetailsSidebar'
import { CandidateCvSidebar } from '@src/pages/Forms/Candidate/CandidateCVSidebar'
import { CandidateEmailSendProfileSidebar } from '@src/pages/Forms/Candidate/CandidateProfileSendEmailSidebar/CandidateEmailSendProfileSidebar'
import { CandidateSchedulingInterview } from '@src/pages/Forms/Candidate/CandidateSchedulingInterview/CandidateSchedulingInterview'
import { CandidateArchive } from '@src/pages/Forms/Candidate/CandidateArchive/CandidateArchive'
import { useQueryClient } from 'react-query'

const CandidateProfileRoot = () => {
  const [candidate, setCandidate] = useState<CandidateInterface>()
  const [round, setRound] = useState<InterviewRoundInterface>()

  const [loading, setLoading] = useState(true)
  const params = useParams<{ id: string }>()
  const { data: stats, refetch: refreshStats } = useGetCandidateStats(params.id)
  const { data: candidateSettings } = useGetCandidateSettings()
  useSetDocumentTitle(candidate?.full_name, 'Recruitment')
  const queryClent = useQueryClient()

  const showCandidateSwitcher = useShowCandidateSwitcher()

  const fetchData = async (force?: boolean) => {
    setLoading(true)

    if (force) {
      setCandidate(undefined)
      setRound(undefined)
    }

    try {
      const candidateResult = await getCandidate(params.id)
      setCandidate(candidateResult.data)

      if (candidateResult.data.active_interview_round) {
        await fetchRound(candidateResult.data.active_interview_round.id)
      }
    } finally {
      setLoading(false)
    }
  }

  const fetchRound = async (roundId: number) => {
    setLoading(true)

    try {
      const roundResult = await getInterviewRound(roundId)
      setRound(roundResult.data)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchData(true)
  }, [params.id])

  const previewStageMode = Boolean(
    candidate?.active_interview_round &&
      round &&
      candidate?.active_interview_round.id !== round.id,
  )
  const canAddRound = previewStageMode
    ? false
    : !!round?.field_options?.permissions?.includes(PermissionTypes.AddInterviewRound)
  const canEditRound = previewStageMode
    ? false
    : !!round?.field_options?.permissions?.includes(PermissionTypes.ChangeInterviewRound)

  const tabs = [
    {
      title: 'Overview',
      path: ROUTES.FORMS.CANDIDATE.SUMMARY,
      to: pathToUrl(ROUTES.FORMS.CANDIDATE.SUMMARY, params),
      icon: <Profile size={15} />,
      component: (
        <Summary
          candidate={candidate}
          previewStageMode={previewStageMode}
          round={round}
          refresh={fetchData}
          isLoading={loading}
          refreshStats={refreshStats}
          canEditRound={canEditRound}
          fetchRound={fetchRound}
        />
      ),
      canView: true,
    },
    {
      title: 'Documents',
      path: ROUTES.FORMS.CANDIDATE.DOCUMENTS,
      icon: <Document size={15} />,
      to: pathToUrl(ROUTES.FORMS.CANDIDATE.DOCUMENTS, params),
      component: <Documents refreshStats={refreshStats} />,
      canView: true,
      quickSummary: <QuickSummaryCount count={stats?.file_count} />,
    },
    {
      title: 'Forms',
      path: ROUTES.FORMS.CANDIDATE.FORMS,
      icon: <Form size={15} />,
      to: pathToUrl(ROUTES.FORMS.CANDIDATE.FORMS, params),
      component: <Forms />,
      canView: true,
      quickSummary: <QuickSummaryCount count={stats?.form_count} />,
    },
    {
      title: 'Emails',
      path: ROUTES.FORMS.CANDIDATE.EMAILS,
      icon: <Envelope size={15} />,
      to: pathToUrl(ROUTES.FORMS.CANDIDATE.EMAILS, params),
      component: <Emails candidateId={candidate?.id} />,
      canView: !!candidateSettings?.enable_emails,
      quickSummary: <QuickSummaryCount count={stats?.thread_count} />,
    },
    {
      title: 'Comments',
      path: ROUTES.FORMS.CANDIDATE.COMMENTS,
      icon: <Chat size={15} />,
      to: pathToUrl(ROUTES.FORMS.CANDIDATE.COMMENTS, params),
      component: <Comments roundId={round?.id} refreshStats={refreshStats} />,
      canView: true,
      quickSummary: <QuickSummaryCount count={stats?.comment_count} />,
    },
    {
      title: 'Timeline',
      path: ROUTES.FORMS.CANDIDATE.TIMELINE,
      icon: <TimelineIcon size={15} />,
      to: pathToUrl(ROUTES.FORMS.CANDIDATE.TIMELINE, params),
      component: <Timeline roundId={round?.id} />,
      canView: true,
      quickSummary: <QuickSummaryCount count={stats?.timeline_event_count} />,
    },
  ].filter(tab => tab.canView)

  if (candidate?.is_anonymised) {
    return <AnonymizedCandidate />
  }

  return (
    <>
      <PageWrapper>
        <PageHeader
          title={
            <PageHeader.Title
              title={
                <Title
                  name={candidate?.full_name}
                  state={round?.state}
                  isConfidential={candidate?.is_confidential}
                  loading={loading}
                />
              }
              labels={
                <CandidateSubtitle
                  candidate={candidate}
                  round={round}
                  loading={loading}
                />
              }
              side={
                showCandidateSwitcher && !loading ? (
                  <CandidateSwitcher candidateId={candidate?.id} />
                ) : undefined
              }
              actions={
                !previewStageMode && (
                  <CandidateActions
                    candidate={candidate}
                    canEditRound={canEditRound}
                    round={round}
                    refresh={fetchData}
                    loading={loading}
                  />
                )
              }
            />
          }
          backUrl={ROUTES.RECRUITMENT.CANDIDATES}
        >
          <Box pb="s-32">
            <TabBarNavigation tabs={tabs} />
          </Box>
        </PageHeader>
        <Flex flexDirection="column" width="100%" flex={1} minHeight={0}>
          <Box maxWidth={{ all: '100%', lg: BREAKPOINTS.xl }}>
            <Switch>
              {tabs.map(tab => (
                <Route exact path={tab.path} key={tab.path}>
                  {tab.component}
                </Route>
              ))}
            </Switch>

            <PersonalDataSidebar onRefresh={fetchData} />
            <CandidateInterviewDetailsSidebar
              round={round}
              refresh={fetchData}
              canAddRound={canAddRound}
              canEditRound={canEditRound}
            />
            <CandidateCvSidebar candidateId={candidate?.id} />
            <CandidateEmailSendProfileSidebar
              candidateId={candidate?.id}
              onRefetch={() => {
                invalidateCandidateEmailThreads(queryClent, candidate?.id)
                refreshStats()
              }}
            />
            <CandidateSchedulingInterview refreshStats={refreshStats} round={round} />
            <CandidateArchive
              refresh={() => {
                fetchData()
                refreshStats()
              }}
              candidate={candidate}
            />
          </Box>
        </Flex>
      </PageWrapper>
    </>
  )
}

export default connect(() => (
  <CandidateProfileContextProvider>
    <CandidateProfileRoot />
  </CandidateProfileContextProvider>
))
