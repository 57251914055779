import React, { useEffect, useMemo, useState } from 'react'
import { Redirect, Route, Switch, useParams, useRouteMatch } from 'react-router-dom'
import { LocationDescriptor, LocationState } from 'history'
import { Box, BREAKPOINTS, Flex, StatusWidget } from '@revolut/ui-kit'
import {
  CalendarDate,
  CenterPoint,
  ChartBarWithArrow,
  Credit,
  DocumentsPair,
  Profile,
  People,
  Target,
  Resort,
  Chat,
  StarFilled,
  Map,
} from '@revolut/icons'
import isEmpty from 'lodash/isEmpty'

import { ROUTES } from '@src/constants/routes'
import { useTheme } from '@src/styles/theme'
import { EmployeeInterface, InternalOrExternalEmployee } from '@src/interfaces/employees'
import { useGetEmployeeStats } from '@src/api/employees'
import { useGetEmployeeDynamicGroups } from '@src/api/dynamicGroups'
import {
  useGetRoadmapSettings,
  useGetTimeOffSettings,
  useGlobalSettings,
} from '@src/api/settings'
import { FeatureFlags, PermissionTypes } from '@src/store/auth/types'
import { goBack, navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import Loader from '@components/CommonSC/Loader'
import { PageWrapper } from '@components/Page/Page'
import {
  Colored,
  ColoredPercent,
} from '@components/ColumnInserts/ColoredPercent/ColoredPercent'
import { PageHeader } from '@components/Page/Header/PageHeader'
import Page404 from '@src/pages/Page404/Page404'
import { TabBarNavigationEditable } from '@src/features/TabBarNavigationEditable/TabBarNavigationEditable'
import { TabBarNavigationNames } from '@src/constants/tabBarNavigation'
import { General } from '@src/pages/EmployeeProfile/Forms'
import { EmployeePageHeader } from '@src/pages/EmployeeProfile/Layout/common/EmployeePageHeader'
import { FormTitle } from '../Preview/components/FormTitle'
import { useEmployeeStatusTransitions } from '../Preview/components/Buttons/ChangeStatusDropdown/StatusChange/hooks'
import { useEmployeeInactivityData } from '../Preview/components/Buttons/ChangeStatusDropdown/SetInactive/hooks'
import { DropdownActionButtonsGroup } from '../Preview/components/Buttons/ActionButtonsGroup'
import { useCanViewReferrals } from '../Preview/components/Buttons/common'
import { useEmployeeProfileData } from '../Preview/hooks'
import {
  canViewDocuments,
  canViewPolicyAssignments,
  canViewScheduleSettings,
  canViewTimeOff,
  useCanViewMeetingsTab,
} from '../Preview/ProfileSummary/common'
import Hierarchy from '../Preview/Talent/Hierarchy'
import { Changelog } from '../Preview/Changelog'
import { Schedule } from '../Preview/Schedule'
import { Referrals } from '../Preview/Referrals'
import { useYear } from '../Preview/Rewards/common'
import { DataAccess } from '../Preview/DataAccess'
import { EmployeeTimeOffPolicies } from '../Preview/TimeOff/EmployeeTimeOffPolicies'
import { SummaryLayoutTab } from './Summary/LayoutTab'
import { PerformanceLayoutTab } from './Performance/LayoutTab'
import { DocumentsLayoutTab } from './Documents/LayoutTab'
import { TimeOffLayoutTab } from './TimeOff/LayoutTab'
import { CompensationPages } from './Compensation'
import { CompensationLayoutTab } from './Compensation/LayoutTab'
import { CompliancePages } from './Compliance'
import { ComplianceLayoutTab } from './Compliance/LayoutTab'
import { performanceRedirects, useIsNewLayout } from './helpers'
import { KPI } from '@src/pages/EmployeeProfile/Preview/KPI'
import { Roadmap } from '@src/pages/EmployeeProfile/Preview/Roadmap'
import { MeetingsTracker } from '@src/pages/Forms/MeetingsTracker/MeetingsTracker'
import { MeetingTrackerEntity } from '@src/interfaces/meetingsTracker'
import { getTalentStatsQuickSummary } from '@src/pages/Forms/CommonTalentTab/TalentStats'
import QuickSummaryCount from '@components/QuickSummary/QuickSummaryCount'
import { Talent } from '@src/pages/EmployeeProfile/Preview/Talent'
import { Engagement } from '@src/pages/EmployeeProfile/Preview/Engagement'
import { overallScoreToColor } from '@src/apps/People/Engagement/helpers'
import { useSelector } from 'react-redux'
import { selectFeatureFlags, selectPermissions } from '@src/store/auth/selectors'
import { Labels } from '@src/pages/EmployeeProfile/Preview/Labels'
import { TeamCalendarView } from '@src/pages/EmployeeProfile/Layout/TimeOff/RequestsWidget/TeamCalendarView'
import { GoalsTab } from '@src/features/Goals/GoalsTab'
import {
  OrgEntityInterface,
  OrgEntityProvider,
} from '@src/features/OrgEntityProvider/OrgEntityProvider'
import { EntityTypes } from '@src/constants/api'
import { useGetTeam } from '@src/api/teams'

const backUrl = ROUTES.PEOPLE.EMPLOYEES

const routes = {
  profile: ROUTES.FORMS.EMPLOYEE.PREVIEW,
  performance: ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.PERFORMANCE.ANY,
  goals: ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.GOALS.ANY,
  kpis: ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.KPI.ANY,
  roadmap: ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.ROADMAP,
  talent: ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.TALENT.ANY,
  meetings: ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.MEETINGS,
  engagement: ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.ENGAGEMENT.ANY,
  documents: ROUTES.FORMS.EMPLOYEE.DOCUMENTS,
  timeOff: ROUTES.FORMS.EMPLOYEE.TIME_OFF.ANY,
  schedule: ROUTES.FORMS.EMPLOYEE.SCHEDULE,
  compensation: ROUTES.FORMS.EMPLOYEE.COMPENSATION_OVERVIEW,
  compliance: ROUTES.FORMS.EMPLOYEE.COMPLIANCE_OVERVIEW,
}

export const EmployeeProfileLayout = () => {
  const theme = useTheme()
  const params = useParams<{ id?: string }>()
  const permissions = useSelector(selectPermissions)
  const featureFlags = useSelector(selectFeatureFlags)
  const isValidEmployeeId = params.id && !isNaN(parseFloat(params.id))
  const employeeId = isValidEmployeeId ? params.id : undefined
  const isNewLayout = useIsNewLayout()

  useEffect(() => {
    // TODO: used for backwards compatibility, should be removed after switching to the new layout
    if (isNewLayout && window.location.hash === '#keyPerson') {
      navigateTo(pathToUrl(ROUTES.FORMS.EMPLOYEE.KEY_PERSON, params))
    }
  }, [])

  const { data, setData, handler, sections } = useEmployeeProfileData({
    employeeId,
    // For the new profile layout we need to load name, position & organisation sections data beforehand,
    // as it's needed to check availability for the "request change" buttons.
    // Should be optimized altogether with API (permissions should arrive from another endpoint)
    instantLoad: {
      name: true,
      position: true,
      organisation: true,
    },
  })
  const entity = useMemo<OrgEntityInterface | undefined>(
    () =>
      data
        ? {
            type: EntityTypes.employees,
            data,
          }
        : undefined,
    [data],
  )
  const { data: dynamicGroupsData } = useGetEmployeeDynamicGroups(employeeId)
  const dynamicGroups = dynamicGroupsData?.results || []
  const { data: stats, refetch: refreshStats } = useGetEmployeeStats(employeeId)
  const { data: roadmapSettings } = useGetRoadmapSettings()
  const canViewMeetingsTab = useCanViewMeetingsTab(data)
  const isInternal = data?.employee_type === 'internal'

  const updateData = (update: Partial<InternalOrExternalEmployee>) => {
    if (!isEmpty(update)) {
      setData({ ...data, ...update } as EmployeeInterface)
    }
  }

  const statusTransitions = useEmployeeStatusTransitions({
    employee: data,
    updateData,
  })
  const employeeInactivity = useEmployeeInactivityData({ employee: data })
  const { settings: globalSettings } = useGlobalSettings()
  const { data: timeOffSettings } = useGetTimeOffSettings()
  const routeMatch = useRouteMatch(Object.values(routes))

  const [compensationPeriodYear, setCompensationPeriodYear] = useYear()
  const [compensationCurrency, setCompensationCurrency] = useState<string>()

  const compensationParams = {
    year: compensationPeriodYear,
    setYear: setCompensationPeriodYear,
    currency: compensationCurrency,
    setCurrency: setCompensationCurrency,
  }

  const canViewReferrals = useCanViewReferrals({ data })
  const { data: teamData } = useGetTeam(data?.team?.id)
  const canViewTeamRequestsPolicy = !!teamData?.field_options.permissions?.includes(
    PermissionTypes.ViewTimeOffRequestPolicy,
  )

  if (!data?.id) {
    return (
      <PageWrapper>
        <Loader />
      </PageWrapper>
    )
  }
  if (!data) {
    return <Page404 />
  }

  const externalPages = [
    {
      key: 'general',
      path: ROUTES.FORMS.EMPLOYEE.GENERAL.ANY,
      component: (
        <General
          data={data}
          dynamicGroups={dynamicGroups}
          refreshData={handler.refetch}
        />
      ),
      canView: true,
    },
    {
      key: 'profile',
      title: 'Employee hierarchy',
      path: ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.TALENT.HIERARCHY,
      component: <Hierarchy data={data} />,
      canView: true,
    },
    {
      key: 'timeOff',
      title: 'Manage Policies',
      path: ROUTES.FORMS.EMPLOYEE.TIME_OFF.POLICIES,
      component: <EmployeeTimeOffPolicies data={data} />,
      canView: canViewPolicyAssignments(data),
    },
    {
      key: 'documents',
      title: 'Documents',
      path: ROUTES.FORMS.EMPLOYEE.DOCUMENTS_BY_CATEGORY,
      component: <DocumentsLayoutTab data={data} mode="page" />,
      canView: canViewDocuments(data),
    },
    {
      key: 'compensation',
      path: ROUTES.FORMS.EMPLOYEE.REWARDS.ANY,
      component: (
        <CompensationPages data={data} compensationParams={compensationParams} />
      ),
      canView: true,
    },
    {
      key: 'compliance',
      path: [
        ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.FINDINGS,
        ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.RISK.ANY,
        ROUTES.FORMS.EMPLOYEE.OWNERSHIP.ANY,
        ROUTES.FORMS.EMPLOYEE.KEY_PERSON,
        ROUTES.FORMS.EMPLOYEE.LINKED_ACCOUNTS,
      ],
      component: (
        <CompliancePages data={data} stats={stats} refreshStats={refreshStats} />
      ),
      canView: true,
    },
    {
      key: 'dataAccess',
      title: 'Data Access',
      path: [ROUTES.FORMS.EMPLOYEE.ACCESS_REQUESTS],
      component: <DataAccess data={data} />,
      canView: true,
    },
    {
      key: 'referrals',
      title: 'Referrals',
      path: ROUTES.FORMS.EMPLOYEE.REFERRALS,
      component: <Referrals data={data} />,
      canView: canViewReferrals,
    },
    {
      key: 'labels',
      title: 'Labels',
      path: ROUTES.FORMS.EMPLOYEE.LABELS,
      component: <Labels data={data} />,
      canView: true,
    },
    {
      key: 'teamCalendarView',
      title: 'Employee team calendar',
      path: ROUTES.FORMS.EMPLOYEE.TIME_OFF.TEAM_CALENDAR,
      component: (
        <TeamCalendarView
          data={data}
          period="Month"
          showTeamViewButton={false}
          showThisMonthButton={false}
          canViewPolicy={canViewTeamRequestsPolicy}
          useFiltersFromQuery
        />
      ),
      canView: canViewTimeOff(data, !!timeOffSettings?.enabled),
    },
  ].filter(({ canView }) => canView)

  const tabs = [
    {
      key: 'profile',
      title: 'Profile' as const,
      icon: <Profile size={15} />,
      path: routes.profile,
      to: pathToUrl(routes.profile, params),
      component: SummaryLayoutTab,
      canView: true,
    },
    {
      key: 'performance',
      title: 'Performance' as const,
      icon: <ChartBarWithArrow size={15} />,
      path: routes.performance,
      to: pathToUrl(routes.performance, params),
      component: PerformanceLayoutTab,
      canView: true,
    },
    {
      key: 'goals',
      title: 'Goals' as const,
      icon: <Target size={15} />,
      quickSummary: stats && (
        <ColoredPercent percent={0} /> // todo: clarify a proper stat field
      ),
      path: routes.goals,
      to: pathToUrl(routes.goals, params),
      canView: featureFlags.includes(FeatureFlags.CanAddGoals),
      component: GoalsTab,
    },
    {
      key: 'kpis',
      title: 'KPIs' as const,
      icon: <Target size={15} />,
      quickSummary: stats && (
        <ColoredPercent percent={stats.kpi_performance_percent * 100} />
      ),
      path: routes.kpis,
      to: pathToUrl(routes.kpis, params),
      canView: true,
      component: KPI,
    },
    {
      key: 'roadmap',
      title: 'Roadmap' as const,
      icon: <Map size={15} />,
      path: routes.roadmap,
      to: pathToUrl(routes.roadmap, params),
      component: Roadmap,
      quickSummary: stats && (
        <ColoredPercent percent={stats.roadmap_progress_percent * 100} />
      ),
      canView: !!roadmapSettings?.enabled,
    },
    {
      key: 'talent',
      title: 'Talent' as const,
      icon: <StarFilled size={15} />,
      path: routes.talent,
      to: pathToUrl(routes.talent, params),
      component: Talent,
      quickSummary: getTalentStatsQuickSummary({
        headcount: stats?.direct_reports_count,
        nips: stats?.direct_reports_nips,
      }),
      canView: true,
    },
    {
      key: 'meetings',
      title: 'Meetings' as const,
      icon: <Chat size={15} />,
      path: routes.meetings,
      to: pathToUrl(routes.meetings, params),
      component: MeetingsTracker,
      canView: canViewMeetingsTab,
    },
    {
      key: 'engagement',
      title: 'Engagement',
      icon: <People size={15} />,
      path: routes.engagement,
      to: pathToUrl(
        ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.ENGAGEMENT.DRIVERS,
        params,
      ),
      component: Engagement,
      canView:
        globalSettings.engagement_enabled &&
        isInternal &&
        permissions.includes(PermissionTypes.ViewEngagementTabs) &&
        data.field_options.permissions?.includes(PermissionTypes.ViewEngagementTabs),
      quickSummary: (
        <QuickSummaryCount
          show0InGrey
          count={data.average_score}
          color={overallScoreToColor(data.average_score)}
        />
      ),
    },
    {
      key: 'documents',
      title: 'Documents' as const,
      icon: <DocumentsPair size={15} />,
      path: routes.documents,
      to: pathToUrl(routes.documents, params),
      component: DocumentsLayoutTab,
      canView: !!data?.field_options?.permissions?.includes(
        PermissionTypes.ViewDocuments,
      ),
      quickSummary: stats?.pending_document_count ? (
        <Colored color={theme.colors.warning}>{stats.pending_document_count}</Colored>
      ) : null,
    },
    {
      key: 'timeOff',
      title: 'Time Off' as const,
      icon: <Resort size={15} />,
      path: routes.timeOff,
      to: pathToUrl(routes.timeOff, params),
      component: TimeOffLayoutTab,
      canView: canViewTimeOff(data, !!timeOffSettings?.enabled),
      quickSummary: stats?.pending_time_off_request_count ? (
        <Colored color={theme.colors.warning}>
          {stats.pending_time_off_request_count}
        </Colored>
      ) : null,
    },
    {
      key: 'schedule',
      title: 'Schedule' as const,
      icon: <CalendarDate size={15} />,
      path: routes.schedule,
      to: pathToUrl(routes.schedule, params),
      component: Schedule,
      canView: canViewScheduleSettings(data, globalSettings.attendance_enabled === true),
      quickSummary: null,
    },
    {
      key: 'compensation',
      title: 'Compensation' as const,
      icon: <Credit size={15} />,
      path: routes.compensation,
      to: pathToUrl(routes.compensation, params),
      component: CompensationLayoutTab,
      canView: true,
    },
    {
      key: 'compliance',
      title: 'Compliance' as const,
      icon: <CenterPoint size={15} />,
      path: routes.compliance,
      to: pathToUrl(routes.compliance, params),
      component: ComplianceLayoutTab,
      canView: true,
    },
  ]

  const filteredTabs = tabs.filter(tab => tab.canView)

  if (data.anonymized?.is_shadow) {
    return (
      <PageWrapper>
        <Box maxWidth={BREAKPOINTS.md} mt="s-64" mx="auto">
          <StatusWidget>
            <StatusWidget.Image
              src="https://assets.revolut.com/assets/3d-images/3D020.png"
              srcSet="https://assets.revolut.com/assets/3d-images/3D020@2x.png 2x, https://assets.revolut.com/assets/3d-images/3D020@3x.png 3x"
            />
            <StatusWidget.Title>Anonymous Profile</StatusWidget.Title>
            <StatusWidget.Description>
              This profile is hidden because it is used to store employee data that has
              been anonymised.
            </StatusWidget.Description>
            <StatusWidget.Action onClick={() => goBack(ROUTES.PEOPLE.EMPLOYEES)}>
              Go Back
            </StatusWidget.Action>
          </StatusWidget>
        </Box>
      </PageWrapper>
    )
  }

  return (
    <OrgEntityProvider entity={entity}>
      <PageWrapper>
        <Switch>
          {Object.entries(performanceRedirects).map(([from, to]) => (
            <Route key={from} exact path={from}>
              <Redirect to={pathToUrl(to, params)} />
            </Route>
          ))}
          {externalPages.map(page => (
            <Route exact key={page.key} path={page.path}>
              {page.title && <EmployeePageHeader data={data} title={page.title} />}
              {page.component}
            </Route>
          ))}
          <Route>
            <PageHeader
              title={
                <FormTitle
                  data={data as EmployeeInterface}
                  refreshData={handler.refetch}
                  actions={
                    <DropdownActionButtonsGroup
                      data={data}
                      refreshData={handler.refetch}
                      statusTransitions={statusTransitions}
                      employeeInactivity={employeeInactivity}
                      sectionsData={sections}
                    />
                  }
                />
              }
              backUrl={backUrl}
            >
              <Box my="s-24" maxWidth={{ all: '100%', lg: BREAKPOINTS.xl }}>
                <TabBarNavigationEditable
                  tabs={filteredTabs}
                  name={TabBarNavigationNames.EmployeeProfile}
                />
              </Box>
            </PageHeader>

            <Flex flexDirection="column" width="100%" flex={1} minHeight={0}>
              <Box maxWidth={{ all: '100%', lg: BREAKPOINTS.xl }}>
                <Switch>
                  {filteredTabs.map(tab => (
                    <Route exact key={tab.path} path={tab.path}>
                      <tab.component
                        data={data}
                        primaryDataHandler={handler}
                        dynamicGroups={dynamicGroups}
                        stats={stats}
                        refreshStats={refreshStats}
                        statusTransitions={statusTransitions}
                        employeeInactivity={employeeInactivity}
                        sectionsData={sections}
                        compensationParams={compensationParams}
                        meetingEntityType={MeetingTrackerEntity.employee}
                      />
                    </Route>
                  ))}
                  <Route exact path={ROUTES.FORMS.EMPLOYEE.CHANGELOG}>
                    <Changelog data={data} />
                  </Route>
                  {!routeMatch && (
                    <Redirect
                      to={filteredTabs[0].to as LocationDescriptor<LocationState>}
                    />
                  )}
                </Switch>
              </Box>
            </Flex>
          </Route>
        </Switch>
      </PageWrapper>
    </OrgEntityProvider>
  )
}
