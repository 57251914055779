import {
  changeInterviewStage,
  skipScheduledInterview,
  unSkipScheduledInterview,
} from '@src/api/recruitment/interviews'
import { InterviewType } from '@src/interfaces/interviewTool'
import { matchPath, useLocation } from 'react-router-dom'
import { ROUTES } from '@src/constants/routes'
import { LOCAL_STORAGE } from '@src/constants/api'

export const changeStage = async (roundId: number, stageId: number) => {
  const response = await changeInterviewStage(roundId, stageId)
  return response.data
}

export const skipInterview = async (roundId: number, stageId: number) => {
  await skipScheduledInterview(roundId, stageId)
}

export const unskipInterview = async (roundId: number, stageId: number) => {
  await unSkipScheduledInterview(roundId, stageId)
}

export const NON_ELIGIBLE_FEEDBACK_STAGES: InterviewType[] = [
  'online_test',
  'offer',
  'cv_screening',
]

export const useShowCandidateSwitcher = () => {
  const location = useLocation<{ history?: string[] }>()
  let savedLocationHistory = []

  try {
    const rawHistory = localStorage.getItem(LOCAL_STORAGE.LOCATION_HISTORY)
    savedLocationHistory = rawHistory ? JSON.parse(rawHistory) : []
  } catch (e) {
    console.error(e)
  }

  try {
    if (savedLocationHistory?.length >= 2) {
      const prevPath = savedLocationHistory[1]
      const prevURL = prevPath
        ? new URL(`${window.location.protocol}${window.location.hostname}${prevPath}`)
        : null

      if (prevURL) {
        return (
          !!matchPath(prevURL.pathname, ROUTES.RECRUITMENT.CANDIDATES) ||
          !!matchPath(
            prevURL.pathname,
            ROUTES.FORMS.HIRING_PIPELINE_OVERVIEW.CANDIDATES,
          ) ||
          (!!matchPath(prevURL.pathname, ROUTES.FORMS.CANDIDATE.ANY) &&
            prevURL.pathname !== location.pathname)
        )
      }
    }

    return false
  } catch (e) {
    console.error(e)
    return false
  }
}
