import React, { useMemo } from 'react'
import {
  CandidateSidebarTypes,
  CandidateInterface,
  InterviewRoundInterface,
  InterviewToolInterviewer,
} from '@src/interfaces/interviewTool'
import {
  Action,
  Box,
  Copyable,
  DetailsCell,
  Flex,
  Group,
  Link,
  DetailsCellSkeleton,
  Text,
  Item,
  Icon,
  Token,
  ItemSkeleton,
  Avatar,
} from '@revolut/ui-kit'
import { pushNotification } from '@src/store/notifications/actions'
import { SUCCESS_DEFAULT_DURATION } from '@src/constants/notifications'
import { NotificationTypes } from '@src/store/notifications/types'
import { pathToUrlWithBaseUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { useGetCV, useGetFullInterviewRounds } from '@src/api/recruitment/interviews'
import { useGetSelectors } from '@src/api/selectors'
import { selectorKeys } from '@src/constants/api'
import UserWithAvatar from '@components/UserWithAvatar/UserWithAvatar'
import pluralize from 'pluralize'
import SalaryDetails from '@src/features/InterviewTool/components/SalaryDetails'
import { useGetHiringProcessSettings } from '@src/api/settings'
import { useGetRightToWorkOptions } from '@src/pages/Settings/HiringProcess/components/RightToWorkInputField'
import OpenInNewTab, { extractHostname } from '@src/components/OpenInNewTab/OpenInNewTab'
import EngagementInfo from '@src/features/InterviewTool/EngagementInfo'
import WorkExperienceInfo from '@src/features/InterviewTool/WorkExperienceInfo'
import EducationInfo from '@src/features/InterviewTool/EducationInfo'
import { getLinkedinUrl } from '@src/utils/hiring'
import CandidateTags from './CandidateTags'

interface Props {
  round?: InterviewRoundInterface
  interviewer?: InterviewToolInterviewer
  title?: string
  candidate: CandidateInterface
  opportunityId?: string
  hometask?: string
  problemSolving?: string
  onOpenSidebar?: (type: CandidateSidebarTypes) => void
  hideCandidate?: boolean
  onShowCV?: () => void
  permissions: CandidateDataPermissions
  interviewingFor?: boolean
}

interface CandidateDataPermissions {
  canAddRound: boolean
  canEditRound: boolean
  canViewCandidate: boolean
  canViewAppliedJobs: boolean
  canOpenAppliedJobs: boolean
  canEditCandidateSidebar: boolean
  canEditPersonalDetails: boolean
}

const CandidateData = ({
  round,
  interviewer,
  title,
  candidate,
  hometask,
  problemSolving,
  onOpenSidebar = () => {},
  hideCandidate,
  onShowCV,
  permissions,
  interviewingFor = true,
}: Props) => {
  const { data: cv, isLoading: loadingCV } = useGetCV(onShowCV ? candidate.id : null)
  const { data: originSelector } = useGetSelectors(
    round?.origin ? selectorKeys.candidate_origin_choices : null,
  )
  const { data: rounds, isLoading: isRoundsLoading } = useGetFullInterviewRounds(
    permissions.canViewAppliedJobs ? candidate.id : null,
  )

  const { data: hiringProcessSettings } = useGetHiringProcessSettings()

  const { value: rightToWorkValue } = useGetRightToWorkOptions(candidate.right_to_work)

  const origin = useMemo(
    () => originSelector?.find(item => item.id === round?.origin)?.name,
    [originSelector, round],
  )

  const linkedIn = getLinkedinUrl(candidate.links)
  const links = candidate.links?.filter(link => link !== linkedIn)

  const isArchived = round?.state === 'archived'
  const engagementInfo = <EngagementInfo candidate={candidate} isArchived={isArchived} />

  return (
    <>
      {(loadingCV || (onShowCV && cv) || linkedIn) && (
        <Group>
          {linkedIn && (
            <Item
              use="a"
              useIcon="LogoLinkedIn"
              href={linkedIn}
              target="_blank"
              aria-label="LinkedIn profile"
            >
              <Item.Content>
                <Item.Title>LinkedIn profile</Item.Title>
              </Item.Content>
              <Item.Side>
                <Icon name="LinkExternal" color={Token.color.blue} />
              </Item.Side>
            </Item>
          )}
          {loadingCV ? (
            <ItemSkeleton>
              <ItemSkeleton.Content>
                <ItemSkeleton.Title />
              </ItemSkeleton.Content>
              <ItemSkeleton.Side />
            </ItemSkeleton>
          ) : (
            <>
              {onShowCV && cv && (
                <Item
                  use="button"
                  useIcon="Document"
                  onClick={() => {
                    onShowCV?.()
                  }}
                >
                  <Item.Content>
                    <Item.Title>Candidate CV</Item.Title>
                  </Item.Content>
                  <Item.Side>
                    <Icon name="ChevronRight" color={Token.color.blue} />
                  </Item.Side>
                </Item>
              )}
            </>
          )}
        </Group>
      )}

      {isArchived && engagementInfo}
      <CandidateTags candidate={candidate} />
      <Group>
        <Item
          use={permissions.canEditPersonalDetails ? 'button' : undefined}
          onClick={
            permissions.canEditPersonalDetails
              ? () => onOpenSidebar('personalData')
              : undefined
          }
          aria-label="Personal details"
        >
          <Item.Avatar>
            <Avatar useIcon="Profile" />
          </Item.Avatar>
          <Item.Content>
            <Item.Title>Personal details</Item.Title>
          </Item.Content>
          <Item.Side>
            {permissions.canEditPersonalDetails && (
              <Icon name="ChevronRight" color={Token.color.blue} />
            )}
          </Item.Side>
        </Item>
        {candidate.email && (
          <DetailsCell data-testid="candidateData_Email">
            <DetailsCell.Title>Email</DetailsCell.Title>
            <DetailsCell.Content>
              <Flex alignItems="center">
                <Box mr="10px">
                  <Copyable
                    value={candidate.email}
                    labelButtonCopy="Copy"
                    onCopy={() => {
                      pushNotification({
                        value: 'Email copied to clipboard',
                        duration: SUCCESS_DEFAULT_DURATION,
                        type: NotificationTypes.success,
                      })
                    }}
                  />
                </Box>
                {candidate.email}
              </Flex>
            </DetailsCell.Content>
          </DetailsCell>
        )}
        {candidate.phone && (
          <DetailsCell data-testid="candidateData_Mobile">
            <DetailsCell.Title>Mobile</DetailsCell.Title>
            <DetailsCell.Content>{candidate.phone}</DetailsCell.Content>
          </DetailsCell>
        )}
        {!!links?.length && (
          <DetailsCell data-testid={`candidateData_Links`}>
            <DetailsCell.Title>Links</DetailsCell.Title>
            <DetailsCell.Content>
              {links.map(link => (
                <OpenInNewTab key={link} link={link} label={extractHostname(link)} />
              ))}
            </DetailsCell.Content>
          </DetailsCell>
        )}
        {problemSolving && (
          <DetailsCell data-testid="candidateData_Problem Solving Case Study">
            <DetailsCell.Title>Problem Solving Case Study</DetailsCell.Title>
            <DetailsCell.Content>
              <Link href={problemSolving} target="_blank" rel="noopener noreferrer">
                Open
              </Link>
            </DetailsCell.Content>
          </DetailsCell>
        )}
        {hometask && (
          <DetailsCell data-testid="candidateData_Hometask">
            <DetailsCell.Title>Hometask</DetailsCell.Title>
            <DetailsCell.Content>
              <Link href={hometask} target="_blank" rel="noopener noreferrer">
                Open
              </Link>
            </DetailsCell.Content>
          </DetailsCell>
        )}
        {candidate.headline && (
          <DetailsCell data-testid="candidateData_Current company">
            <DetailsCell.Title>Current company</DetailsCell.Title>
            <DetailsCell.Content>{candidate.headline}</DetailsCell.Content>
          </DetailsCell>
        )}
        {!candidate.country && candidate.location && (
          <DetailsCell data-testid="candidateData_Current location">
            <DetailsCell.Title>Current location</DetailsCell.Title>
            <DetailsCell.Content>{candidate.location}</DetailsCell.Content>
          </DetailsCell>
        )}
        {candidate.country && (
          <DetailsCell data-testid="candidateData_Current country">
            <DetailsCell.Title>Current country</DetailsCell.Title>
            <DetailsCell.Content>{candidate.country.name}</DetailsCell.Content>
          </DetailsCell>
        )}
        {candidate.current_position && (
          <DetailsCell>
            <DetailsCell.Title>Current position</DetailsCell.Title>
            <DetailsCell.Content>{candidate.current_position}</DetailsCell.Content>
          </DetailsCell>
        )}
        {candidate.years_of_experience !== null && (
          <DetailsCell>
            <DetailsCell.Title>Years of experience</DetailsCell.Title>
            <DetailsCell.Content>{candidate.years_of_experience}</DetailsCell.Content>
          </DetailsCell>
        )}
        {/* {(candidate.local_current_salary !== null ||
            candidate?.candidate_declined_to_disclose_current_salary)  && (
          <DetailsCell>
            <DetailsCell.Title>Current salary (annual)</DetailsCell.Title>
            <DetailsCell.Content>
              <SalaryDetails
                canView={(candidate?.field_options?.actions ?? []).includes(
                  'view_salary',
                )}
                convertedCurrency={candidate?.local_currency}
                convertedSalary={candidate?.local_current_salary}
                additionalCommentary={
                  candidate?.additional_information_about_current_salary
                }
              />
            </DetailsCell.Content>
          </DetailsCell>
        )} */}
        {hiringProcessSettings?.enable_right_to_work_collecting && (
          <>
            {rightToWorkValue && (
              <DetailsCell>
                <DetailsCell.Title>Right to work</DetailsCell.Title>
                <DetailsCell.Content>{rightToWorkValue.name}</DetailsCell.Content>
              </DetailsCell>
            )}
            {typeof candidate.is_eligible_to_relocate === 'boolean' && (
              <DetailsCell>
                <DetailsCell.Title>Eligibility to relocate</DetailsCell.Title>
                <DetailsCell.Content>
                  {candidate.is_eligible_to_relocate ? 'Yes' : 'No'}
                </DetailsCell.Content>
              </DetailsCell>
            )}
            {typeof candidate.is_ready_to_relocate === 'boolean' && (
              <DetailsCell>
                <DetailsCell.Title>Ready to relocate</DetailsCell.Title>
                <DetailsCell.Content>
                  {candidate.is_ready_to_relocate ? 'Yes' : 'No'}
                </DetailsCell.Content>
              </DetailsCell>
            )}
          </>
        )}
      </Group>
      <Box>
        <Group>
          <Item
            use={
              permissions.canEditRound || permissions.canAddRound ? 'button' : undefined
            }
            onClick={
              permissions.canEditRound || permissions.canAddRound
                ? () => onOpenSidebar('candidate')
                : undefined
            }
            aria-label="Interview details"
          >
            <Item.Avatar>
              <Avatar useIcon="Microphone" />
            </Item.Avatar>
            <Item.Content>
              <Item.Title>Interview details</Item.Title>
            </Item.Content>
            <Item.Side>
              {(permissions.canEditRound || permissions.canAddRound) && (
                <Icon name="ChevronRight" color={Token.color.blue} />
              )}
            </Item.Side>
          </Item>

          {title && (
            <DetailsCell data-testid="candidateData_Current stage">
              <DetailsCell.Title>Current stage</DetailsCell.Title>
              <DetailsCell.Content>{title}</DetailsCell.Content>
            </DetailsCell>
          )}
          {!hideCandidate && (
            <DetailsCell data-testid="candidateData_Candidate">
              <DetailsCell.Title>Candidate</DetailsCell.Title>
              <DetailsCell.Content>
                {permissions.canViewCandidate ? (
                  <Link
                    href={pathToUrlWithBaseUrl(ROUTES.FORMS.CANDIDATE.SUMMARY, {
                      id: candidate.id,
                    })}
                    target="_blank"
                  >
                    {candidate.full_name}
                  </Link>
                ) : (
                  candidate.full_name
                )}
              </DetailsCell.Content>
            </DetailsCell>
          )}
          {interviewer && (
            <DetailsCell data-testid="candidateData_Interviewer">
              <DetailsCell.Title>Interviewer</DetailsCell.Title>
              <DetailsCell.Content>
                <UserWithAvatar {...interviewer} usePathWithBaseUrl />
              </DetailsCell.Content>
            </DetailsCell>
          )}
          {interviewingFor && (
            <DetailsCell data-testid="candidateData_Interviewing for">
              <DetailsCell.Title>Interviewing for</DetailsCell.Title>
              <DetailsCell.Content>
                {round?.specialisation ? (
                  <Link
                    href={pathToUrlWithBaseUrl(ROUTES.FORMS.SPECIALISATIONS.PREVIEW, {
                      id: round.specialisation.id,
                    })}
                    target="_blank"
                  >
                    {round.specialisation.name}
                  </Link>
                ) : (
                  '-'
                )}
              </DetailsCell.Content>
            </DetailsCell>
          )}
          <DetailsCell data-testid="candidateData_Seniority">
            <DetailsCell.Title>Seniority</DetailsCell.Title>
            <DetailsCell.Content>{round?.seniority?.name || '-'}</DetailsCell.Content>
          </DetailsCell>
          <DetailsCell data-testid="candidateData_Recruiter assigned">
            <DetailsCell.Title>Recruiter assigned</DetailsCell.Title>
            <DetailsCell.Content>
              <Flex>
                {round?.recruiter ? (
                  <UserWithAvatar {...round.recruiter} usePathWithBaseUrl />
                ) : (
                  '-'
                )}
              </Flex>
            </DetailsCell.Content>
          </DetailsCell>
          <DetailsCell data-testid="candidateData_Hiring manager">
            <DetailsCell.Title>Hiring manager</DetailsCell.Title>
            <DetailsCell.Content>
              <Flex>
                {round?.hiring_manager ? (
                  <UserWithAvatar {...round.hiring_manager} usePathWithBaseUrl />
                ) : (
                  '-'
                )}
              </Flex>
            </DetailsCell.Content>
          </DetailsCell>
          {origin && (
            <DetailsCell data-testid="candidateData_Source">
              <DetailsCell.Title>Source</DetailsCell.Title>
              <DetailsCell.Content>{origin}</DetailsCell.Content>
            </DetailsCell>
          )}
          {round?.created_by && (
            <DetailsCell data-testid="candidateData_Created by">
              <DetailsCell.Title>
                {round.origin === 'referral' ? 'Referred by' : 'Sourced by'}
              </DetailsCell.Title>
              <DetailsCell.Content>
                <UserWithAvatar {...round.created_by} usePathWithBaseUrl />
              </DetailsCell.Content>
            </DetailsCell>
          )}
          {permissions.canViewAppliedJobs && (
            <>
              {isRoundsLoading ? (
                <DetailsCellSkeleton />
              ) : (
                <DetailsCell data-testid="candidateData_Applied for">
                  <DetailsCell.Title>Applied for</DetailsCell.Title>
                  <DetailsCell.Content>
                    {rounds ? (
                      <>
                        {permissions.canOpenAppliedJobs ? (
                          <Action onClick={() => onOpenSidebar('rounds')}>
                            {rounds.length} applied {pluralize('job', rounds.length)}
                          </Action>
                        ) : (
                          <Text>
                            {rounds.length} applied {pluralize('job', rounds.length)}
                          </Text>
                        )}
                      </>
                    ) : (
                      '-'
                    )}
                  </DetailsCell.Content>
                </DetailsCell>
              )}
            </>
          )}
          {round?.requisition && (
            <DetailsCell>
              <DetailsCell.Title>Requisition</DetailsCell.Title>
              <DetailsCell.Content>
                <Link
                  href={pathToUrlWithBaseUrl(ROUTES.FORMS.REQUISITION.ROLE, {
                    id: round.requisition.id,
                  })}
                  target="_blank"
                >
                  {round.requisition.requisition_title}
                </Link>
              </DetailsCell.Content>
            </DetailsCell>
          )}
          {round?.preferred_location && (
            <DetailsCell>
              <DetailsCell.Title>Desired location</DetailsCell.Title>
              <DetailsCell.Content>{round?.preferred_location?.name}</DetailsCell.Content>
            </DetailsCell>
          )}
          {(round?.converted_expected_salary !== null ||
            round?.local_expected_salary !== null ||
            round?.candidate_declined_to_disclose_expected_salary) && (
            <DetailsCell>
              <DetailsCell.Title>Desired salary (annual)</DetailsCell.Title>
              <DetailsCell.Content>
                <SalaryDetails
                  canView={(round?.field_options?.actions ?? []).includes('view_salary')}
                  convertedCurrency={round?.conversion_currency}
                  convertedSalary={round?.converted_expected_salary}
                  localCurrency={round?.local_currency}
                  localSalary={round?.local_expected_salary}
                  additionalCommentary={
                    round?.additional_information_about_expected_salary
                  }
                  candidateDeclinedToDisclose={
                    round?.candidate_declined_to_disclose_expected_salary
                  }
                />
              </DetailsCell.Content>
            </DetailsCell>
          )}
        </Group>
      </Box>
      <WorkExperienceInfo
        data={candidate.work_experiences}
        years={candidate.years_of_experience}
      />
      <EducationInfo data={candidate.educations} />
      {!isArchived && engagementInfo}
    </>
  )
}

export default CandidateData
