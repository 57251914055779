import React from 'react'
import {
  Absolute,
  Avatar,
  ErrorWidget,
  Flex,
  Group,
  Item,
  ItemSkeleton,
  Relative,
  Spinner,
  Tag,
  Text,
  Token,
  VStack,
  Icon,
} from '@revolut/ui-kit'
import {
  InterviewFeedbackInterface,
  InterviewRoundInterface,
  InterviewStageWithoutRoundInterface,
  InterviewType,
  ScheduleSidebarModeType,
} from '@src/interfaces/interviewTool'
import { StagesLoadingStatusType } from '@src/pages/Forms/Candidate/InterviewProgress/useFetchStagesTable'
import { StagesTableStage } from '@src/pages/Forms/Candidate/StagesTable/StagesTableStage'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'

interface Props {
  round?: InterviewRoundInterface
  onClick?: (
    data: InterviewStageWithoutRoundInterface | InterviewFeedbackInterface,
    mode?: ScheduleSidebarModeType,
    stageType?: InterviewType,
  ) => void
  onRefresh?: (onlyTable?: boolean) => void
  selectedItemId?: number
  disableActions?: boolean
  canViewEditOffer: boolean
  stages: InterviewStageWithoutRoundInterface[]
  status?: StagesLoadingStatusType
  candidateId?: number
}

export const StagesTable = ({
  round,
  canViewEditOffer,
  stages,
  candidateId,
  onClick,
  status,
  disableActions,
  onRefresh,
  selectedItemId,
}: Props) => {
  if (stages.length === 0 && status === 'loading') {
    return (
      <Group data-testid="stages-table-skeleton">
        <ItemSkeleton />
        <ItemSkeleton />
        <ItemSkeleton />
        <ItemSkeleton />
        <ItemSkeleton />
        <ItemSkeleton />
        <ItemSkeleton />
        <ItemSkeleton />
        <ItemSkeleton />
        <ItemSkeleton />
        <ItemSkeleton />
      </Group>
    )
  }

  if (!round || !candidateId) {
    return null
  }

  return (
    <Relative>
      {status === 'loading' && (
        <Absolute width="100%" height="100%" zIndex={1}>
          <Flex
            alignItems="center"
            justifyContent="center"
            backgroundColor={Token.color.white_60}
            height="100%"
            borderRadius={Token.radius.widget}
          >
            <Spinner size={44} color={Token.color.accent} />
          </Flex>
        </Absolute>
      )}

      <Group>
        <Item
          use="a"
          href={pathToUrl(ROUTES.FORMS.SPECIALISATIONS.HIRING_PROCESS, {
            id: round.specialisation.id,
          })}
          target="_blank"
          color="inherit"
        >
          <Item.Avatar>
            <Avatar useIcon="Carousel" />
          </Item.Avatar>
          <Item.Content>
            <Flex alignItems="center">
              <Item.Title>{round.specialisation.name}</Item.Title> &nbsp;&nbsp;
              <Icon name="LinkExternal" size={14} />
            </Flex>

            <Item.Description>Hiring stages</Item.Description>
          </Item.Content>
          <Item.Side>
            <Tag color={Token.color.green} variant="outlined">
              Main
            </Tag>
          </Item.Side>
        </Item>

        {!stages.length && (
          <ErrorWidget>
            <ErrorWidget.Title>
              <VStack align="center" space="s-8" mt="s-8">
                <Icon name="ExclamationTriangle" color={Token.color.greyTone20} />
                <Text color={Token.color.greyTone50}>
                  Unable to view the hiring stages for this candidate
                </Text>
              </VStack>
            </ErrorWidget.Title>
            <ErrorWidget.Description>
              Please try to refresh the page or contact our team
            </ErrorWidget.Description>
            <ErrorWidget.Action onClick={() => onRefresh?.()}>Refresh</ErrorWidget.Action>
          </ErrorWidget>
        )}

        {stages.map(stage => (
          <StagesTableStage
            key={stage.id}
            stage={stage}
            round={round}
            canViewEditOffer={canViewEditOffer}
            stages={stages}
            candidateId={candidateId}
            onClick={onClick}
            disableActions={disableActions}
            onRefresh={onRefresh}
            selectedItemId={selectedItemId}
          />
        ))}
      </Group>
    </Relative>
  )
}
