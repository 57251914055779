import React, { useEffect, useMemo } from 'react'
import { Redirect, Route, Switch, useParams } from 'react-router-dom'
import { connect, useLape } from 'lape'
import { ROUTES } from '@src/constants/routes'
import Loader from '@components/CommonSC/Loader'
import * as RoleRole from '@src/pages/Forms/RoleForm/General'
import * as Preview from '@src/pages/Forms/RoleForm/Preview/Preview'
import * as RoleCompetencyMatrix from '@src/pages/Forms/RoleForm/CompetencyMatrix/CompetencyMatrix'
import * as RoleHiringProcess from '@src/pages/Forms/RoleForm/HiringProcess/HiringProcess'
import KpiTab from '@src/pages/Forms/RoleForm/Kpi/KPI'
import Talent from '@src/pages/Forms/RoleForm/Talent/Talent'
import { RoleInterface } from '@src/interfaces/roles'
import * as Requisitions from '@src/pages/Forms/RoleForm/Requisitions/Requisitions'
import { TalentStatsInterface } from '@src/interfaces/functions'
import { rolesRequestsNew, roleTalentTabStats } from '@src/api/roles'
import { validator as generalValidator } from '@src/pages/Forms/RoleForm/General/General'
import Form from '@src/features/Form/Form'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { Box, chain, Flex } from '@revolut/ui-kit'
import { PageHeader } from '@components/Page/Header/PageHeader'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'
import { pathToUrl } from '@src/utils/router'
import ValidationPre from '@src/features/TabBarNavigation/ValidationPre'
import Specialisations from '@src/pages/Forms/RoleForm/Specialisations/Specialisations'
import { PageWrapper } from '@components/Page/Page'
import { Statuses } from '@src/interfaces'
import upperFirst from 'lodash/upperFirst'
import { Status } from '@components/CommonSC/General'
import { useGetMatrixValidatorAndSeniorities } from '@src/features/CompetencyMatrixTable/utils'
import { getTalentStatsQuickSummary } from '@src/pages/Forms/CommonTalentTab/TalentStats'
import QuickSummaryCount from '@components/QuickSummary/QuickSummaryCount'
import { Engagement } from './Engagement/Engagement'
import { PermissionTypes } from '@src/store/auth/types'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import {
  useGetHiringProcessSettings,
  useGetOrganisationSettings,
  useGlobalSettings,
} from '@src/api/settings'
import { overallScoreToColor } from '@src/apps/People/Engagement/helpers'
import { useGetPerformanceSettings } from '@src/api/performanceSettings'
import { EntityTypes } from '@src/constants/api'
import {
  OrgEntityInterface,
  OrgEntityProvider,
} from '@src/features/OrgEntityProvider/OrgEntityProvider'
import { canViewTalentPerformance } from '../CommonTalentTab/utils'

const Roles = () => {
  const params = useParams<{ id?: string }>()
  const permissions = useSelector(selectPermissions)
  const { values, valid, dirty, isSubmitting } = useLapeContext<RoleInterface>()
  const entity = useMemo<OrgEntityInterface | undefined>(
    () => (values.id ? { type: EntityTypes.role, data: values } : undefined),
    [values.id],
  )

  const { data: settings } = useGetPerformanceSettings()
  const { data: organisationSettings } = useGetOrganisationSettings()
  const { data: hiringProcessSettings } = useGetHiringProcessSettings()

  const {
    settings: { engagement_enabled, requisitions_enabled, candidates_enabled },
  } = useGlobalSettings()

  const kpiEnabled = !!settings?.enable_function_role_specialisation_kpis

  const state = useLape<{ loading: boolean; stats?: TalentStatsInterface }>({
    loading: !!params.id,
    stats: undefined,
  })
  const backUrl = ROUTES.FUNCTION.ROLES

  const { matrixValidated, seniorities } = useGetMatrixValidatorAndSeniorities(values)

  useEffect(() => {
    if (params.id) {
      roleTalentTabStats(params.id).then(res => {
        state.stats = res.data
        state.loading = false
      })
    }
  }, [])

  if (state.loading) {
    return (
      <PageWrapper>
        <Loader />
      </PageWrapper>
    )
  }

  const isNewInstance = !values?.id || values.status === Statuses.draft
  const isHiringProcessFilled = matrixValidated && !!values?.hiring_process_rounds?.length

  const tabs = [
    {
      title: 'General',
      path: ROUTES.FORMS.ROLE.GENERAL,
      to: pathToUrl(ROUTES.FORMS.ROLE.GENERAL, params),
      preTitle: <ValidationPre isVisible={isNewInstance} isValid={valid} />,
      component: RoleRole.default,
      canView: isNewInstance,
    },
    {
      title: 'Preview',
      path: ROUTES.FORMS.ROLE.PREVIEW,
      to: pathToUrl(ROUTES.FORMS.ROLE.PREVIEW, params),
      component: Preview.default,
      canView: !isNewInstance,
    },
    {
      title: 'KPI',
      path: ROUTES.FORMS.ROLE.KPI,
      to: pathToUrl(ROUTES.FORMS.ROLE.KPI, params),
      component: KpiTab,
      canView: kpiEnabled && !isNewInstance,
    },
    {
      title: 'Competency matrix',
      path: ROUTES.FORMS.ROLE.COMPETENCY_MATRIX,
      to: pathToUrl(ROUTES.FORMS.ROLE.COMPETENCY_MATRIX, params),
      disabled: isNewInstance && !valid,
      preTitle: (
        <ValidationPre isVisible={isNewInstance && valid} isValid={matrixValidated} />
      ),
      component: RoleCompetencyMatrix.default,
    },
    {
      title: 'Hiring process',
      path: ROUTES.FORMS.ROLE.HIRING_PROCESS,
      disabled: isNewInstance && !matrixValidated,
      to: pathToUrl(ROUTES.FORMS.ROLE.HIRING_PROCESS, params),
      preTitle: (
        <ValidationPre
          isVisible={isNewInstance && matrixValidated}
          isValid={isHiringProcessFilled}
        />
      ),
      component: RoleHiringProcess.default,
      canView:
        candidates_enabled && hiringProcessSettings?.enable_role_level_process_definition,
    },
    {
      title: 'Preview',
      path: ROUTES.FORMS.ROLE.PREVIEW,
      disabled: isNewInstance && !isHiringProcessFilled,
      to: pathToUrl(ROUTES.FORMS.ROLE.PREVIEW, params),
      component: Preview.default,
      canView: isNewInstance,
    },
    {
      title: 'Specialisations',
      path: ROUTES.FORMS.ROLE.SPECIALISATIONS,
      to: pathToUrl(ROUTES.FORMS.ROLE.SPECIALISATIONS, params),
      component: Specialisations,
      canView: !isNewInstance,
    },
    {
      title: 'Talent',
      path: ROUTES.FORMS.ROLE.TALENT.ANY,
      to: pathToUrl(ROUTES.FORMS.ROLE.TALENT.ANY, params),
      quickSummary: getTalentStatsQuickSummary({
        headcount: values?.headcount,
        nips: state.stats?.nips,
      }),
      component: Talent,
      canView: canViewTalentPerformance(values.field_options),
    },
    {
      title: 'Requisitions',
      path: ROUTES.FORMS.ROLE.REQUISITIONS,
      to: pathToUrl(ROUTES.FORMS.ROLE.REQUISITIONS, params),
      quickSummary: <QuickSummaryCount count={values.requisition_headcount} />,
      component: Requisitions.default,
      canView: !isNewInstance && requisitions_enabled,
    },
    {
      title: 'Engagement',
      path: ROUTES.FORMS.ROLE.ENGAGEMENT.ANY,
      to: pathToUrl(ROUTES.FORMS.ROLE.ENGAGEMENT.DRIVERS, params),
      component: Engagement,
      canView:
        engagement_enabled &&
        !isNewInstance &&
        permissions.includes(PermissionTypes.ViewEngagementTabs),
      quickSummary: (
        <QuickSummaryCount
          show0InGrey
          count={values.average_score}
          color={overallScoreToColor(values.average_score)}
        />
      ),
    },
  ]

  const filteredTabs = tabs.filter(organisationSubtab => {
    if (organisationSubtab.canView === undefined) {
      return true
    }

    return organisationSubtab.canView
  })

  return (
    <OrgEntityProvider entity={entity}>
      <PageWrapper>
        <PageHeader
          title={chain(
            values.name || 'New Role',
            values.status && !!organisationSettings?.enable_roles_approvals && (
              <Status status={values.status as Statuses}>
                {upperFirst(values.status)}
              </Status>
            ),
          )}
          backUrl={backUrl}
        >
          <Box pb="s-16" maxWidth="100vw">
            <TabBarNavigation
              isDisabled={isNewInstance && dirty}
              disabledTooltip="Please save your changes."
              key={`${isSubmitting}`}
              tabs={filteredTabs}
            />
          </Box>
        </PageHeader>
        <Flex flexDirection="column" minHeight={0}>
          <Switch>
            {filteredTabs.map(tab => (
              <Route exact path={tab.path} key={tab.path}>
                <tab.component
                  data={values}
                  matrixValidated={matrixValidated}
                  seniorities={seniorities}
                />
              </Route>
            ))}
            {filteredTabs[0]?.path ? <Redirect to={filteredTabs[0].path} /> : null}
          </Switch>
        </Flex>
      </PageWrapper>
    </OrgEntityProvider>
  )
}

const PageRole = () => {
  return (
    <Form api={rolesRequestsNew} validator={generalValidator}>
      <Roles />
    </Form>
  )
}
export default connect(PageRole)
