import React, { useContext, useEffect, useMemo, useState } from 'react'
import { OptionInterface } from '@src/interfaces/selectors'
import { Button, StatusPopup, useStatusPopup } from '@revolut/ui-kit'
import { navigateReplace } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import {
  archiveInterviews,
  bulkArchiveCandidates,
  useGetUnarchivedInterviewRounds,
} from '@src/api/recruitment/interviews'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import {
  SendCandidateEmailInterface,
  SendCandidateEmailPayloadInterface,
} from '@src/interfaces/hiringProccess'
import { API, LOCAL_STORAGE } from '@src/constants/api'
import { CandidateInterface } from '@src/interfaces/interviewTool'
import { getMessageFromError, pushNotification } from '@src/store/notifications/actions'
import { NotificationTypes } from '@src/store/notifications/types'
import { ERROR_DEFAULT_DURATION } from '@src/constants/notifications'
import BulkStatusPopup from '@components/BulkStatusPopup/BulkStatusPopup'
import { FileInterface } from '@src/interfaces/files'
import { clearCVScreeningSession } from '@src/pages/Forms/CVScreening/utils'
import * as Sentry from '@sentry/react'
import { getPrefilledPlaceholdersEmail } from '@src/api/hiringProcess'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { workspaceLocalStorage } from '@src/features/Workspaces/workspaceLocalStorage'
import { useQueryClient } from 'react-query'

export interface ArchivingContextInterface {
  reason: OptionInterface | undefined | null
  setReason: React.Dispatch<React.SetStateAction<OptionInterface | undefined | null>>
  comments: string
  setComments: React.Dispatch<React.SetStateAction<string>>
  archiveAll: boolean
  setArchiveAll: React.Dispatch<React.SetStateAction<boolean>>
  roundId?: number
  setRoundId: React.Dispatch<React.SetStateAction<number | undefined>>
  setSendEmail: (checked: boolean) => void
  sendEmail: boolean
}

const ArchivingContext = React.createContext<ArchivingContextInterface | null>(null)

interface ArchivingProviderProps {
  children: React.ReactNode
}

export const ArchivingProvider = ({ children }: ArchivingProviderProps) => {
  const [reason, setReason] = useState<OptionInterface | undefined | null>()
  const [sendEmail, setSendEmail] = useState<boolean>(false)
  const [comments, setComments] = useState<string>('')
  const [archiveAll, setArchiveAll] = useState<boolean>(false)
  const [roundId, setRoundId] = useState<number>()

  const contextValue = useMemo(
    () => ({
      reason,
      setReason,
      comments,
      setComments,
      archiveAll,
      setArchiveAll,
      roundId,
      setRoundId,
      setSendEmail,
      sendEmail,
    }),
    [
      reason,
      setReason,
      comments,
      setComments,
      archiveAll,
      setArchiveAll,
      roundId,
      setRoundId,
      setSendEmail,
      sendEmail,
    ],
  )

  return (
    <ArchivingContext.Provider value={contextValue}>{children}</ArchivingContext.Provider>
  )
}

export const useArchiving = () => {
  const context = useContext(ArchivingContext)
  if (context === null) {
    throw new Error(`useArchiving must be used within an ArchivingProvider`)
  }
  return context
}

interface ArchiveButtonProps {
  candidateId: number
  step?: 'archive' | 'email'
  pending?: boolean
  disabled?: boolean
  scheduled?: boolean
  onAfterArchive?: () => void
  onLoading?: (isLoading: boolean) => void
  noSuccessPopup?: boolean
}

interface BulkArchiveButtonProps
  extends Omit<ArchiveButtonProps, 'candidateId' | 'step'> {
  candidateIds?: (number | string)[]
  sendEmail?: boolean
}

export const ArchiveButton = ({
  candidateId,
  pending,
  disabled,
  step = 'archive',
  scheduled,
  onAfterArchive,
  onLoading,
  noSuccessPopup,
}: ArchiveButtonProps) => {
  const statusPopup = useStatusPopup()
  const [successPopupOpen, setSuccessPopupOpen] = useState(false)
  const { reason, comments, archiveAll, roundId } = useArchiving()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { data: rounds, isLoading: isRoundsLoading } = useGetUnarchivedInterviewRounds(
    archiveAll ? candidateId : null,
  )
  const queryClient = useQueryClient()

  const afterSuccess = () => {
    queryClient.invalidateQueries(
      pathToUrl(API.FULL_INTERVIEW_ROUNDS, { id: candidateId }),
    )
    if (onAfterArchive) {
      onAfterArchive()
    } else {
      navigateReplace(
        pathToUrl(ROUTES.FORMS.CANDIDATE.SUMMARY, {
          id: candidateId,
        }),
      )
    }
  }

  const onArchive = async () => {
    if (!reason || !roundId || (archiveAll && !rounds)) {
      pushNotification({
        type: NotificationTypes.error,
        value: 'Rounds or round id is missing',
        duration: ERROR_DEFAULT_DURATION,
      })
      return
    }
    setIsLoading(true)
    onLoading?.(true)
    const roundIds = archiveAll ? rounds?.map(r => r.id) || [] : [roundId]

    try {
      await archiveInterviews(candidateId, roundIds, reason, comments)
      if (noSuccessPopup) {
        afterSuccess()
      } else {
        setSuccessPopupOpen(true)
      }
    } catch (e) {
      const errorMsg = getMessageFromError(e)
      statusPopup.show(
        <StatusPopup variant="error">
          <StatusPopup.Title>Cannot archive the candidate</StatusPopup.Title>
          {errorMsg && <StatusPopup.Description>{errorMsg}</StatusPopup.Description>}
        </StatusPopup>,
      )
      Sentry.captureException(e)
    } finally {
      setIsLoading(false)
      onLoading?.(false)
    }
  }

  const btnPending = pending || isLoading || isRoundsLoading

  return (
    <>
      {step === 'archive' && (
        <Button
          elevated
          disabled={disabled || btnPending}
          onClick={onArchive}
          pending={btnPending}
        >
          Archive
        </Button>
      )}
      {step === 'email' && (
        <NewSaveButtonWithPopup<SendCandidateEmailInterface>
          successText="Email has been sent"
          onAfterSubmit={onArchive}
          useValidator
          noPopup
          disabled={disabled || btnPending}
          pending={btnPending}
        >
          Archive & {scheduled ? 'schedule' : 'send'} email
        </NewSaveButtonWithPopup>
      )}

      <StatusPopup
        variant="success"
        open={successPopupOpen}
        onClose={() => {
          setSuccessPopupOpen(false)
          afterSuccess()
        }}
        // @ts-expect-error
        labelButtonClose="Close success popup"
      >
        <StatusPopup.Title>
          {step === 'archive' && 'Candidate archived'}
          {step === 'email' && scheduled && 'Candidate archived & email scheduled'}
          {step === 'email' && !scheduled && 'Candidate archived & email sent'}
        </StatusPopup.Title>
      </StatusPopup>
    </>
  )
}

export const BulkArchiveButton = ({
  candidateIds,
  pending,
  disabled,
  sendEmail,
  scheduled,
  onAfterArchive,
  onLoading,
}: BulkArchiveButtonProps) => {
  const [isSuccess, setIsSuccess] = useState<boolean | null>(null)
  const { reason, comments, archiveAll } = useArchiving()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const onClick = async () => {
    if (!reason || !candidateIds?.length) {
      pushNotification({
        type: NotificationTypes.error,
        value: 'Reason or candidates list are missing',
        duration: ERROR_DEFAULT_DURATION,
      })
      return
    }
    setIsLoading(true)
    onLoading?.(true)

    try {
      await bulkArchiveCandidates(candidateIds, archiveAll, reason, comments)
      setIsSuccess(true)
    } catch (e) {
      setIsSuccess(false)
    } finally {
      setIsLoading(false)
      onLoading?.(false)
    }
  }

  return (
    <>
      {sendEmail ? (
        <NewSaveButtonWithPopup<SendCandidateEmailInterface>
          successText="Email has been sent"
          onAfterSubmit={onClick}
          onSubmitError={e => {
            if (e?.response?.status !== 400) {
              setIsSuccess(false)
            }
          }}
          pending={pending || isLoading}
          useValidator
          noPopup
          disabled={disabled}
        >
          Archive & {scheduled ? 'schedule' : 'send'} emails
        </NewSaveButtonWithPopup>
      ) : (
        <Button
          elevated
          disabled={disabled}
          onClick={onClick}
          pending={pending || isLoading}
        >
          Archive
        </Button>
      )}
      <BulkStatusPopup
        isSuccess={isSuccess}
        onClose={() => {
          setIsSuccess(null)
          onAfterArchive?.()
        }}
      />
    </>
  )
}

export interface RememberedDataInterface
  extends Pick<
      ArchivingContextInterface,
      'reason' | 'comments' | 'sendEmail' | 'archiveAll'
    >,
    SendCandidateEmailPayloadInterface {}

export const getRememberedArchivingData = (): RememberedDataInterface | null => {
  const rememberRawData = workspaceLocalStorage.getItem(
    LOCAL_STORAGE.ARCHIVING_DETAILS_REMEMBER_CHOICE,
  )
  if (rememberRawData) {
    try {
      const rememberDataParsed: RememberedDataInterface = JSON.parse(rememberRawData)

      return rememberDataParsed
    } catch (e) {
      clearCVScreeningSession()
      return null
    }
  }

  return null
}

export const saveRememberArchivingData = (data: RememberedDataInterface) => {
  workspaceLocalStorage.setItem(
    LOCAL_STORAGE.ARCHIVING_DETAILS_REMEMBER_CHOICE,
    JSON.stringify(data),
  )
}

export const getUpdatedArchivingData = async (
  candidateId: number,
): Promise<RememberedDataInterface | null> => {
  const rememberedData = getRememberedArchivingData()
  try {
    if (rememberedData && rememberedData.email_template?.id) {
      const { data } = await getPrefilledPlaceholdersEmail(
        rememberedData.email_template.id,
        candidateId,
      )
      const newRememberedData = {
        ...rememberedData,
        when_to_send: data.when_to_send
          ? { ...data.when_to_send }
          : rememberedData.when_to_send,
        custom_sending_datetime: data.custom_sending_datetime,
        email_template: data.email_template
          ? { ...data.email_template }
          : rememberedData.email_template,
        sender_type: data.sender_type,
        email_body: data.email_body,
        recipients_cc: data.recipients_cc,
        recipients_bcc: data.recipients_bcc,
        subject: data.subject,
        attachments: (data.attachments as FileInterface[]) ?? [],
      }
      return newRememberedData
    }
  } catch {
    return null
  }
  return rememberedData
}

export const usePrefillRememberedChoice = (
  setRememberChoiceChecked: (checked: boolean) => void,
  candidate?: CandidateInterface,
  candidateIds?: (number | string)[],
) => {
  const { values } = useLapeContext<SendCandidateEmailInterface>()
  const { setReason, setComments, setArchiveAll, setSendEmail } = useArchiving()
  const [loading, setLoading] = useState(true)
  const updateValues = async (candidateId: number) => {
    setLoading(true)
    const data = await getUpdatedArchivingData(candidateId)

    if (data) {
      setComments(data.comments)
      setReason(data.reason)
      setArchiveAll(data.archiveAll)
      setSendEmail(data.sendEmail)

      values.when_to_send = data.when_to_send ? { ...data.when_to_send } : undefined
      values.custom_sending_datetime = data.custom_sending_datetime
      values.email_template = data.email_template ? { ...data.email_template } : undefined
      values.sender_type = data.sender_type
      values.email_body = data.email_body
      values.recipients_cc = data.recipients_cc
      values.recipients_bcc = data.recipients_bcc
      values.subject = data.subject
      values.attachments = (data.attachments as FileInterface[]) || []

      setRememberChoiceChecked(true)
    }
    setLoading(false)
  }

  useEffect(() => {
    if (candidate?.id) {
      updateValues(candidate.id)
    } else {
      setLoading(false)
    }
  }, [candidate, candidateIds])

  return {
    loading,
  }
}
